<template>
        <base-page>
        <titlu-pagina Titlu="Remindere" @on_add_clicked="show_dialog()" :AdaugaVisible="$has_right('adaugare-reminder')" />
        <el-card style='margin:5px 0px 5px 0px'>
            <div slot="header" class="clearfix">
                <strong> Filtre </strong>
            </div>
            <div class="filtre">
                <el-form @submit.prevent.native='refresh_info()'>
                    <el-row :gutter="20">
                        
                        <el-col :md='4'>
                            <el-form-item label='Mesaj' >
                                <el-input v-model='Filters.Mesaj' />
                            </el-form-item>
                        </el-col>
                        <el-col :md='4'>
                            <el-form-item label='Data executie' >
                                <el-date-picker v-model='Filters.DataExecutie' type='datetime' value-format='yyyy-MM-dd HH:mm:ss' format='dd.MM.yyyy HH:mm' />
                            </el-form-item>
                        </el-col>
                        <el-col :md='4'>
                            <el-form-item label='Data adaugare' >
                                <el-date-picker v-model='Filters.DataAdaugare' type='datetime' value-format='yyyy-MM-dd HH:mm:ss' format='dd.MM.yyyy HH:mm' />
                            </el-form-item>
                        </el-col>
                        <el-col :md='4'>
                            <el-form-item label='Este validat' >
                                <el-checkbox class='full-width' v-model='Filters.EsteValidat' true-label='1' false-label='0'> Este validat </el-checkbox>
                            </el-form-item>
                        </el-col>
                        <el-col :md='4'>
                            <el-form-item label='Status' >
                                <el-select class='full-width' v-model='Filters.Status' >
                                    <el-option label='Toate' value='-1'></el-option>
                                    <el-option label='creat' value='creat'></el-option>
                                    <el-option label='sters' value='sters'></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>                        
                        <el-col :md='24' >
                            <el-button type='primary' native-type='submit' @click='refresh_info()'> Aplica </el-button>
                        </el-col>

                    </el-row>
                </el-form>
                </div>
        </el-card>

        <el-table :data="Results" >
            <el-table-column prop='Mesaj' label='Mesaj'></el-table-column>
            <el-table-column label='Data executie'>
                <template slot-scope='scope'>
                    {{ scope.row.DataExecutie | momentformat('dd.MM.YYYY') }}
                </template>
            </el-table-column>
            <el-table-column label='Data adaugare'>
                <template slot-scope='scope'>
                    {{ scope.row.DataAdaugare | momentformat('dd.MM.YYYY') }}
                </template>
            </el-table-column><el-table-column prop='IdUtilizator' label='Utilizator'></el-table-column>
            <el-table-column prop='EsteValidat' label='Este validat'></el-table-column>
            <el-table-column prop='Status' label='Status'></el-table-column>
            <el-table-column fixed="right" label="Actiuni" width="200px" >
                <template slot-scope="scope" >

                    <el-tooltip v-if="$has_right('modificare-reminder')" content="Modificare">
                        <el-button type="primary" icon="el-icon-edit"  circle @click="show_dialog(scope.row.Id)" />
                    </el-tooltip>

                    <el-tooltip v-if="$has_right('stergere-reminder')" content="Sterge" >
                        <el-button type="danger" icon="el-icon-delete" circle @click="delete_item(scope.row)" />
                    </el-tooltip>

                </template>
            </el-table-column>
        </el-table>
    <el-pagination @size-change="refresh_info" @current-change= "refresh_info" :page-size.sync="PaginationInfo.PerPage" :current-page.sync="PaginationInfo.Page" :total="PaginationInfo.RowCount" layout="pager" />
<!-- <Remindere-dialog ref='dlg' @save="refresh_info()" /> -->
    </base-page>
</template>

<script>
import settings from "@/backend/LocalSettings";
import BasePage from "@/pages/BasePage";
import Remindere_dialog from '@/pages/remindere/Remindere_dialog.vue';
import TitluPagina from '@/widgets/TitluPagina';

export default {
    name: "remindere",
    extends: BasePage,
    components: {
        'base-page': BasePage,
        'Remindere-dialog': Remindere_dialog,
        'titlu-pagina': TitluPagina
    },
    data () {
        return {
            Results: [],
            base_url: '',
            Info: {
                            },
            Filters: {
                Mesaj: '' , DataExecutie: '' , DataAdaugare: '' , EsteValidat: '' , Status: '-1' ,             },
            OrderBy: { },
            PaginationInfo: { Page: 1, PerPage: 50, RowCount: 0, PageSizes: [10, 25, 50, 100, 200] },
        }
    },
    methods: {

        async get_info(){
            if (!this.$has_right('vizualizare-remindere')){
                this.$router.push('/no-rights')
                return
            }
            var response        = await this.post("remindere/get_info" );
            this.refresh_info();
        },

        async refresh_info(){
            var response        = await this.post("remindere/index", { Filters: this.Filters, OrderBy: this.OrderBy, PaginationInfo: this.PaginationInfo } );
            this.Results        = response.Results;
            this.PaginationInfo = response.PaginationInfo;
            //
            this.select_menu_item('remindere');
        },
        reset(){
            this.Filters = {
                Mesaj: '' , DataExecutie: '' , DataAdaugare: '' , EsteValidat: '' , Status: '-1' ,             };
            this.refresh_info();
        },

        async delete_item( item ){            
            this.$confirm(`Sunteti sigur ?`, 'Warning', {               
                type: 'warning'
                }).then(async() => {
                    await this.post("remindere/delete_item", { id: item.Id } );
                    this.refresh_info(); 
                    this.$message({type: 'info', message: 'Stergere efectuata cu succes'});                                                                       
                    }).catch(() => {
                        this.$message({type: 'info', message: 'Stergere anulata'});                                                                 
                        });
        },

        show_dialog(id){
            this.$refs.dlg.show_me(id);
        }
    },
    mounted(){
        this.base_url = settings.BASE_URL;
        this.get_info();
    }
};
</script>

<style lang="less" scoped>

    .top50{
        margin-top: 20px;
    }

    .filtre{
        .el-input-number
        {
            width: 100% !important;
        }
    }

</style>
